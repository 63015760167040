<template>
  <div class="news-detail">
    <div class="row justify-content-start" v-if="news">
      <div class="col-2"></div>
      <div class="col-6" style="margin: 50px 0px 0px 0px;">
        <h1>{{news.title}}</h1>
        <br />
        <hr />
        <br />
        <img :src="news.image1" style="width:700px; margin-top:20px;" />
        <br />
        <span>{{news.content1}}</span>
        <br />
        <img :src="news.image2" style="width:700px; margin-top:20px;" />
        <br />
        <span>{{news.content2}}</span>
        <br />
        <img :src="news.image3" style="width:700px; margin-top:20px;" />
        <br />
        <span>{{news.content3}}</span>
        <br />
        <img :src="news.image4" style="width:700px; margin-top:20px;" />
        <br />
        <span>{{news.content4}}</span>
        <br />
        <img :src="news.image5" style="width:700px; margin-top:20px;" />
        <br />
        <span>{{news.content5}}</span>
      </div>
      <div class="col-2">
        <v-side style="width:240px; height:600px; margin: 50px 0px 0px 0px;" />
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Side from '../components/Side'
export default {
  name: 'NewsDetail',
  components: {
    'v-side': Side
  },
  mounted() {
    this.getNewsDetail(this.$route.query.id)
  },
  data() {
    return {
      news: null
    }
  },
  methods: {
    getNewsDetail(id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/e-car/chinaybop-news/getById', {
            // .get('http://localhost:9091/car/news/getById', {
            params: {
              id: id
            }
          })
          .then(res => {
            this.news = res.data
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
</script>

<style scoped>
@import '../assets/css/news.css';
</style>
